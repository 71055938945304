import * as React from "react"

function FavouriteIcon(props) {
  return (
    <svg width={159} height={142} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M51.165.504C21.669 2.417-2.734 26.978.245 65.208c3.784 48.46 48.272 85.049 100.948 74.358 40.661-8.258 69.064-52.574 51.798-97.365C137.135 1.021 83.337-1.569 51.165.504"
          fill="#FDE5EF"
          fillRule="nonzero"
        />
        <path
          d="M52.66 116.315a90.646 90.646 0 00-3.165 18.273 84.89 84.89 0 0043.87 6.23 38.417 38.417 0 002.417-20.33l-43.121-4.173zM70.713 25.19c-2.043-2.95-1.94-7.241 2.233-10.406 4.172-3.166 8.574-2.96 11.866.157 0 0 7.871-1.326 10.058 4.703 2.187 6.028 2.709 10.766-2.483 16.5-5.192 5.733-2.929 6.4-7.692 5.451-4.762-.95-10.826-8.948-10.826-8.948s-5.515.759-3.156-7.457z"
          fill="#1D3655"
          fillRule="nonzero"
        />
        <path
          d="M52.532 67.395c-.906.187-9.827 16.1-10.36 17.266C36.705 96.33 47.525 93.05 50 88.978a102.92 102.92 0 007.064-14.043c1.41-3.195-4.532-7.54-4.532-7.54zm59.782 21.898c-1.439-4.605-2.993-9.137-4.633-13.122-.302-.518-4.431-8.015-4.734-8.533-.2-.344-.47-.643-.79-.877l-.317-.432a3.093 3.093 0 00-3.885-1.108 3.396 3.396 0 00-1.67 1.87c-1.712.59-2.877 5.252-1.438 7.195a45.165 45.165 0 015.323 8.086c1.64 3.669 4.317 10.072 4.317 10.072 6.46 5.395 7.827-3.151 7.827-3.151z"
          fill="#C2C2C2"
          fillRule="nonzero"
        />
        <path
          d="M97.164 122.07c-3.856-15.726-2.503-33.784-1.439-41.898.547.661 3.108 3.28 3.108 3.28l8.92-7.28a110.344 110.344 0 00-8.474-17.698 25.899 25.899 0 00-8.978-8.863c-3.928-2.029-17.381-4.317-27.05 1.985-4.403 2.878-9.928 9.9-12.489 14.158-1.065 1.77-4.849 8.547-4.849 8.547l10.072 5.755a39.481 39.481 0 012.878-4.417c-1.295 5.324-2.677 29.813-7.799 41.41a64.747 64.747 0 0020.46 8.345c11.928 2.662 25.64-3.324 25.64-3.324z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M85.266 39.38a3.971 3.971 0 00-2.62-1.884l-.531-.087a4.316 4.316 0 00-4.317.23c-2.49 1.583-2.374 6.26-2.662 8.864-.288 2.604-1.165 6.446.374 9.007 1.54 2.56 5.08 2.993 7.784 1.842 1.684-.72 1.05-1.842.763-3.67.532-3.222.806-8.877 1.324-12.1.374-2.029 1.323-1.554-.115-2.201z"
          fill="#C2C2C2"
          fillRule="nonzero"
        />
        <path
          d="M87.61 20.92a8.072 8.072 0 00-8.762-2.964 3.799 3.799 0 00-1.698.734c-4.907 3.683-5.986 11.625-5.353 17.265.303 2.677.964 5.928 2.878 7.986.75.863 1.76 1.46 2.878 1.698a4.72 4.72 0 003.554-.504 7.079 7.079 0 001.611-1.093 18.287 18.287 0 003.022-3.252 22.345 22.345 0 003.654-9.137c.633-3.669.49-7.683-1.784-10.733z"
          fill="#C2C2C2"
          fillRule="nonzero"
        />
        <path
          d="M94.033 25.562c.445-3.58-2.082-1.793-3.89-4.916-.818-1.464-1.852-.67-3.296-1.524-.719-.446-1.438-.777-2.187-1.252a16.705 16.705 0 01-1.841-1.799 2.173 2.173 0 00-2.878-.244.201.201 0 000-.087c-.849-2.877-5.41-1.438-4.561 1.44 1.08 3.683 2.059 6.221 5.167 8.293 3.108 2.072 3.452.132 7.36 2.407 3.907 2.275.987 1.463 3.348 3.147 2.36 1.685 2.893-3.552 2.778-5.465z"
          fill="#1D3655"
          fillRule="nonzero"
        />
        <path
          d="M90.2 49.61c-3.352-1.74-13.64-3.568-22.575-.244.044.136.113.262.202.374 3.683 4.92 5.093 11.194 6.906 16.964a1.324 1.324 0 001.525.92 2.475 2.475 0 002.1-1.078c1.54-2.015 2.778-4.317 4.317-6.418a118.2 118.2 0 018.144-10.2c-.173-.116-.432-.231-.619-.317z"
          fill="#C2C2C2"
          fillRule="nonzero"
        />
        <path
          d="M116.171 55.338l-20.374 7.597.202.302 20.676-7.497c2.029-.748 2.92-1.928-.504-.402zm1.28 1.237c-3.05.85-21.669 6.734-21.669 6.734l.13.36s20.633-6.115 22.071-6.547c1.44-.432 2.533-1.396-.532-.547z"
          fill="#1E3656"
          fillRule="nonzero"
        />
        <path
          d="M105.912 56.848l5.093 4.389a31.654 31.654 0 012.303 5.841c.172 1.525 5.309 21.698 5.755 24.834.446 3.137-3.957 9.67-6.36 8.561-2.403-1.107-7.194-6.33-5.41-11.395 1.784-5.065 1.525-18.561 1.209-19.87-.317-1.31-3.583-1.44-2.978-4.187.604-2.748.46-1.784.46-1.784l-3.482-2.605.676-.863 2.878 1.583-2.648-3.194 2.504-1.31zM47.151 87.769a106.473 106.473 0 0010.82 9.021 13.798 13.798 0 012.705-1.74l6.23-2.965.36 1.252-3.295 2.259 4.316-1.439L70 97.035l-3.44 3.395s-7.668 6.446-13.898.245c0 0-6.662-1.928-9.655-4.317-2.993-2.388-3.654-7.194-2.244-8.633a4.433 4.433 0 016.388.044z"
          fill="#C2C2C2"
          fillRule="nonzero"
        />
        <path
          fill="#9D9D9D"
          fillRule="nonzero"
          d="M73.237 88.876l17.697 3.526-6.56.503-18.03-3.597z"
        />
        <path
          stroke="#9D9D9D"
          strokeWidth={0.76}
          fill="#FFF"
          fillRule="nonzero"
          d="M60.302 109.178l1.827-20.705 18.992 3.784-6.258 19.828z"
        />
        <path
          stroke="#9D9D9D"
          strokeWidth={0.76}
          fill="#9D9D9D"
          fillRule="nonzero"
          d="M78.1 112.732l-3.237-.647 6.258-19.827 3.252.647z"
        />
        <path
          d="M90.646 88.502a1.9 1.9 0 011.44 2.662l-.303.619-.33.72-.447-.102-17.77-3.525 1.022-2.172a1.885 1.885 0 012.086-1.05l13.137 2.618 1.079.216.086.014z"
          fill="#9D9D9D"
          fillRule="nonzero"
        />
        <path
          d="M73.352 97.912c.274-.15.47-.413.532-.72a1.065 1.065 0 00-.849-1.266 1.094 1.094 0 00-.978.302 3.928 3.928 0 00-1.655-.733 4.043 4.043 0 00-1.813 0 1.08 1.08 0 00-2.057.187c-.058.305.021.62.216.863a4.115 4.115 0 00-1.137 2.1 4.072 4.072 0 007.985 1.583 3.971 3.971 0 00-.244-2.36"
          fill="#D5146D"
          fillRule="nonzero"
        />
        <path
          d="M68.805 103.236a3.77 3.77 0 01-2.878-4.418 3.64 3.64 0 011.05-1.928 3.554 3.554 0 011.684-.964 3.683 3.683 0 013.208.633c.525.401.937.932 1.195 1.54.295.69.37 1.453.215 2.187a3.755 3.755 0 01-4.402 2.878"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M69.15 101.423a.935.935 0 001.15-.662l-1.956-.389a.92.92 0 00.806 1.05m.216-1.107s.733 0 .79-.345c.058-.345-.46-.302-.647-.345-.187-.043-.705-.101-.733 0-.03.1.532.647.59.633m2.82-1.972c-.302-.302-1.036-.59-1.438-.244a.619.619 0 00-.187.82c.271.39.449.838.518 1.31 0 .359.129.532.402.59.533-.041.98-.418 1.108-.936a1.554 1.554 0 00-.431-1.54"
          fill="#D5146D"
          fillRule="nonzero"
        />
        <path
          d="M71.222 99.005a.23.23 0 01-.172-.259.216.216 0 01.259-.158.187.187 0 01.158.245.216.216 0 01-.245.172"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M68.963 97.682c-.23-.46-1.022-.475-1.44-.317a1.439 1.439 0 00-.977 1.266 1.194 1.194 0 00.647 1.266c.273 0 .446 0 .604-.374.246-.41.58-.759.979-1.021a.619.619 0 00.158-.82"
          fill="#D5146D"
          fillRule="nonzero"
        />
        <path
          d="M68.23 98.402a.201.201 0 01-.159-.245.201.201 0 01.245-.172c.114.021.19.13.172.244a.23.23 0 01-.259.173"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M87.092 73.452a7.928 7.928 0 00-11.209-11.208 7.928 7.928 0 00-11.208 11.208l11.208 11.194 11.195-11.194h.014zM62.416 55.899a1.022 1.022 0 00-1.438 0 .993.993 0 000 1.439l.992.993a.993.993 0 001.44 0 .978.978 0 000-1.44l-.994-.992zM89.294 85.61a.993.993 0 001.438 0 .978.978 0 000-1.438l-.978-.993a1.022 1.022 0 00-1.439 0 .993.993 0 000 1.439l.979.992zM57.25 71.755a1.007 1.007 0 000-2h-1.438a1.007 1.007 0 000 2h1.439zm38.619 0a1.007 1.007 0 000-2H94.43a1.007 1.007 0 000 2h1.439zM62.416 85.61l.993-.992a.993.993 0 000-1.44 1.022 1.022 0 00-1.439 0l-.992.994a.993.993 0 000 1.438 1.02 1.02 0 001.438 0zm27.309-27.309l.978-.993a.978.978 0 000-1.438.993.993 0 00-1.439 0l-.992.992a.993.993 0 000 1.44 1.022 1.022 0 001.453 0zm-13.87 30.058c-.556 0-1.007.45-1.007 1.007v1.439a1.007 1.007 0 102 0v-1.44a.993.993 0 00-.993-1.006zm-1.036-36.216a1.007 1.007 0 102 0v-1.439a1.007 1.007 0 10-2 0v1.439zm33.568 16.403a1.885 1.885 0 11-1.914-1.885 1.87 1.87 0 011.885 1.885"
          fill="#6FCF97"
          fillRule="nonzero"
        />
        <path
          d="M25.54 75.035a1.842 1.842 0 11-3.684 0 1.842 1.842 0 013.683 0"
          fill="#153759"
          fillRule="nonzero"
        />
        <path
          fill="#6FCF97"
          fillRule="nonzero"
          d="M81.236 43.452l-.244 3.396 3.078-1.482z"
        />
        <path
          fill="#153759"
          fillRule="nonzero"
          d="M99.23 92.644l-2.24 3.306 3.986.292z"
        />
        <path
          fill="#6FCF97"
          fillRule="nonzero"
          d="M36.95 96.344l-4.303-2.504 2.503-4.302 4.317 2.517z"
        />
        <path
          fill="#5C92CE"
          fillRule="nonzero"
          d="M123.596 77.28l-4.302-2.505 2.517-4.287 4.302 2.503z"
        />
        <path
          stroke="#90B4DC"
          strokeWidth={1.399}
          strokeLinecap="round"
          d="M34.215 45.236l2.173 2.374 3.237.015L41.812 50"
        />
        <path
          stroke="#6FCF97"
          strokeWidth={1.399}
          strokeLinecap="round"
          d="M102.114 51.61l2.964-.301 1.798-2.36 2.965-.316 1.798-2.36 2.964-.302"
        />
        <path
          d="M116.185 86.33c-1.713-.26-1.44-2-3.151-2.26-1.713-.258-1.986 1.44-3.698 1.224-1.712-.216-1.439-2-3.166-2.26"
          stroke="#90B4DC"
          strokeWidth={1.399}
          strokeLinecap="round"
        />
        <path
          d="M37.553 69.524c-1.697-.374-1.309-2.087-3.007-2.46-1.698-.375-2.072 1.337-3.755.963-1.683-.374-1.31-2.1-3.007-2.474-1.698-.375-2.072 1.338-3.77.964-1.698-.375-1.31-2.087-3.007-2.46"
          stroke="#6FCF97"
          strokeWidth={1.399}
          strokeLinecap="round"
        />
        <path
          d="M46.575 58.545a3.51 3.51 0 11-3.511-3.482 3.497 3.497 0 013.51 3.482"
          fill="#5C92CE"
          fillRule="nonzero"
        />
        <path
          d="M119.538 94.142a1.352 1.352 0 11-1.353-1.352 1.338 1.338 0 011.353 1.352"
          fill="#6FCF97"
          fillRule="nonzero"
        />
        <path
          fill="#90B4DC"
          fillRule="nonzero"
          d="M43.05 82.546l2.646-2.59-3.582-.978z"
        />
        <path
          fill="#153759"
          fillRule="nonzero"
          d="M117.357 52.606l3.526 1.18-.749-3.625z"
        />
        <path
          fill="#5C92CE"
          fillRule="nonzero"
          d="M60.46 48.532l-1.626-3.338 3.352-1.612 1.626 3.324z"
        />
        <path
          stroke="#153759"
          strokeWidth={1.399}
          strokeLinecap="round"
          d="M57.122 95.74l-1.886-2.835m-.475 2.361l2.835-1.886"
        />
      </g>
    </svg>
  )
}

export default FavouriteIcon

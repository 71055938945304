/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { useContext,useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { Header, LoginHeader } from "../../components/Header";
import UserContext from "../../context/User";
import useStyle from "./styles";
import Analytics from "../../utils/analytics";

function PrivacyPolicy() {
  useEffect(async()=>{
    await Analytics.track(Analytics.events.NAVIGATE_TO_PRIVACY_POLICY)
  },[])
  const classes = useStyle();
  const { isLoggedIn } = useContext(UserContext);
  return (
    <Grid className={classes.root}>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Grid container className={classes.mainContainer}>
        <Box className={classes.imageContainer}>
          <Typography variant="h4" color="textPrimary" align="center" className={classes.title}>
            Privacy Policy
          </Typography>
        </Box>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={1} md={1} />
          <Grid container item xs={10} sm={10} md={9}>
            <Typography color="textSecondary" variant="subtitle2" className={classes.MV3}>
              This policy explains how we, enatega, a brand of
              <RouterLink to={{ pathname: "https://enatega.com" }} target="_blank" className={classes.linkDecoration}>
                <Typography variant="caption" color="primary" className={classes.link}>
                  {" https://enatega.com"}
                </Typography>
              </RouterLink>
              , use your personal information which you provide to us when using our service, including but not limited
              to our website and mobile applications (apps).
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              What information we collect about you?
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              We collect personal information from you when you order goods or services from us or use our website. We
              also collect information when you complete any customer survey. Website usage information may also be
              collected using cookies.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Cookies and Google Analytics
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Cookies are small text files that are placed on your computer by websites that you visit. They are widely
              used in order to make websites work, or work more efficiently, as well as to provide information to the
              owners of the site.
            </Typography>
            <Typography variant="subtitle2">
              This website uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google
              Analytics uses "cookies", which are text files saved on your computer, to help the website analyze how you
              use the site. The information generated by the cookie about your use of the website will be transmitted to
              and stored by Google on servers in the United States. If this website anonymizes IP addresses, your IP
              address will be truncated by Google within a EU member state or other EEA state before being transmitted
              to the US. Only in exceptional situations will your full IP address be transmitted to Google servers in
              the United States and truncated there. Google will use this information for the purpose of evaluating your
              use of the website, compiling reports on website activity for website operators and providing other
              services relating to website activity and internet usage. Google will not associate your IP address with
              any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on
              your browser, however please note that if you do this you may not be able to use the full functionality of
              this website. By using this website, you consent to the processing of data about you by Google in the
              manner and for the purposes set out above. You can also prevent Google from collecting information
              (including your IP address) via cookies and processing this information by downloading this browser plugin
              and installing it:{" "}
              <RouterLink
                to={{ pathname: "https://tools.google.com/dlpage/gaoptout" }}
                target="_blank"
                className={classes.linkDecoration}
              >
                <Typography variant="caption" color="primary" className={classes.link}>
                  {" https://tools.google.com/dlpage/gaoptout"}
                </Typography>
              </RouterLink>
              <Typography variant="subtitle2" className={classes.MV3}>
                Most web browsers allow some control of most cookies through the browser settings. To find out more
                about cookies, including how to see what cookies have been set and how to manage and delete them, visit
                <RouterLink
                  to={{ pathname: "www.allaboutcookies.org" }}
                  target="_blank"
                  className={classes.linkDecoration}
                >
                  <Typography variant="caption" color="primary" className={classes.link}>
                    {" www.allaboutcookies.org"}
                  </Typography>
                </RouterLink>
              </Typography>
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              How will we use the information we collect from you?
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Information that we collect from you is used to process your order and to manage your account. We may also
              use your information to email you about other products or services that we think may be of interest to
              you.
            </Typography>
            <Typography variant="subtitle2">
              In processing your order we may send your information to credit reference and fraud prevention agencies.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              From time to time we may send your information to third parties which we consider may have goods or
              services which are of interest to you. If you do not wish to be contacted by third parties please email us
              at
              <Typography variant="caption" color="primary" className={classes.link}>
                {" info@enatega.com"}
              </Typography>
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Access to your information
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              You have a right to request a copy of the information we hold on you at any time. Please email us if you
              would like to receive a copy of this information –{" "}
              <Typography variant="caption" color="primary" className={classes.link}>
                {" info@enatega.com"}
              </Typography>
              . There will be a small charge for processing this request.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Other Websites
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Our website may have links to other websites. This privacy policy only applies to this website. You should
              therefore read the privacy policies of the other websites when you are using those sites.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default React.memo(PrivacyPolicy);
